window.numberFormat = function(
    value,
    decimalPlaces=1,
    startGroup=1,
    suffixes = 'long',
    keepIntegers = true,
    spacer=' '
) {
    let absoluteValue = Math.abs(value)
    if(!absoluteValue) return '0';

    if (suffixes === 'long') {
        suffixes = ['', 'thousand', 'million', 'billion', 'trillion', 'quadrillion', 'quintillion', 'sextillion'];
    } else if (suffixes === 'short') {
        suffixes = ['', 'k', 'm', 'b', 't', 'qd', 'qt', 'st']
    }

    if (absoluteValue < 10 ** (3 * startGroup)) {
         return keepIntegers && Number.isInteger(value) ? value.toString() : value.toFixed(decimalPlaces);
    }

    let exponent = Math.floor(Math.log10(absoluteValue));
    let group = Math.floor(exponent / 3);
    let maxGroup = suffixes.length - 1;
    let selectedGroup = group <= maxGroup ? group : maxGroup;

    return `${(value/10**(3*selectedGroup)).toFixed(decimalPlaces)}${spacer}${suffixes[selectedGroup]}`;
}

window.percentageFormat = function(numerator, denominator, decimalPlaces=1) {
    if (!numerator || !denominator) return 0;
    return (numerator / denominator * 100).toFixed(decimalPlaces)+'%';
}
