import proj4 from 'proj4';
window.proj4 = proj4;

import Highcharts from 'highcharts';

import stock from 'highcharts/modules/stock';
import gantt from 'highcharts/modules/gantt';
import map from 'highcharts/modules/map';
import exporting from 'highcharts/modules/exporting'
import exportData from 'highcharts/modules/export-data';
import offlineExporting from 'highcharts/modules/offline-exporting';
import seriesLabel from 'highcharts/modules/series-label';
import noDataToDisplay from 'highcharts/modules/no-data-to-display';

stock(Highcharts);
gantt(Highcharts);
map(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
offlineExporting(Highcharts);
seriesLabel(Highcharts);
noDataToDisplay(Highcharts);

const defaultOptions = {
    credits: {
        enabled: false,
    },
    exporting: {
        buttons: {
            contextButton: {
                menuItems: [
                    "viewFullscreen",
                    "printChart",
                    "separator",
                    "downloadPNG",
                    "downloadJPEG",
                    // "downloadPDF",
                    "downloadSVG",
                    "separator",
                    "downloadCSV",
                    "downloadXLS",
                ]
            }
        },
        scale: 1,
        sourceWidth: 1920,
        sourceHeight: 1080,
        fallbackToExportServer: false,
        error: function () {
            toastr.error('An error occurred while exporting. Retry later');
        }
    }
}

Highcharts.setOptions(defaultOptions);
window.Highcharts = Highcharts;
