$(function () {
    $('#issueModalForm').on('submit', function(e) {
        const data = Object.fromEntries(new FormData($(this)[0]));
        const meta = {
            origin: 'echo-web',
            metadata: {
                location: {
                    protocol: window.location.protocol,
                    hostname: window.location.hostname,
                    port: window.location.port,
                    pathname: window.location.pathname,
                    search: window.location.search,
                    referrer: document.referrer
                },
                navigator: {
                    platform:  navigator.platform,
                    userAgent:  navigator.userAgent,
                    version:  navigator.appVersion,
                    vendor: navigator.vendor,
                    language:  navigator.language
                },
                screen: {
                    screenWidth:  screen.width,
                    screenHeight:  screen.height,
                    innerWidth:  innerWidth,
                    innerHeight:  innerHeight
                },
                cookie: Cookies.get()
            },
        };

        $.ajax({
            type: 'POST', url: '/api/send_issue/', contentType: 'application/json',
            headers: {'X-CSRFToken': Cookies.get('csrftoken')},
            data: JSON.stringify(Object.assign(data, meta))
        })
            .done(function() {
                toastr.success('Report sent. Thank you so much for taking the time to help us!');
            })
            .fail(function() {
                toastr.error('There was a problem sending the issue report. Please send it to our <a href="mailto:echo.team@zoppas.com">support team</a> email instead.');
            })
            .always(function() {
                $('#issueModal').modal('hide');
                $('#issueModalForm').trigger('reset');
                $('#issueModalForm select').trigger('reset');
            });

        e.preventDefault();
    });

    $('div.language-flags > a.dropdown-item').on('click', function (e) {
        const item = $(this), form = item.siblings('form');
        form.find('input[name="language"]').val(item.data('value'));
        form.submit();
        e.preventDefault();
    });
});
