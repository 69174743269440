window.dateShift = function(date, {years=0, months=0, days=0}) {
    return new Date(date.getFullYear() + years, date.getMonth() + months, date.getDate() + days);
}

window.dateAPIFormat = function(date) {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

window.durationFormat = function(value, decimalPlaces=1, spacer=' ') {
    if (!value) return 0;
    if (value < 60)
        return value + spacer + 's';
    if (value < 3600)
        return (value / 60).toFixed(decimalPlaces) + spacer + 'm';
    return (value / 3600).toFixed(decimalPlaces) + spacer + 'h';
}

window.formatDate = function (datetimeString) {
    const date = new Date(datetimeString);
    const options = {year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false};
    return date.toLocaleString('en-US', options);
}
