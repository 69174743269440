import 'datatables.net'
import "datatables.net-bs4";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons-bs4";
import "datatables.net-responsive";
import "datatables.net-select";

// Default options: {order: 'current', page: 'all', search: 'none'}
$.fn.dataTable.Api.register('page.jump()', function (data, column, options) {
    let position = this.column(column, options).data().indexOf(data);
    if (position >= 0) {
        let page = Math.floor(position / this.page.info().length);
        this.page(page).draw(false);
    }
    return this;
});

$.extend( $.fn.dataTable.defaults, {
    processing: true,
    responsive: true,
    language: {
        search: '',
        searchPlaceholder: 'Search',
        paginate: {previous: '&lt;', next: '&gt;'},
        processing: `
            <div id="loading" class="text-center text-muted text-lg">
                <div class="spinner-border text-primary align-middle mr-2" role="status"></div>
                <span>Loading</span>
            </div>
        `
    }
});
