window.getCountryListExtremes = function(map, offset=50) {
    let extremes = null;
    map.get('countries').data.forEach(function ({isNull, _minX, _maxX, _minY, _maxY}) {
        if (!isNull && _minX && _maxX && _minY && _maxY) {
            if (!extremes) {
                extremes = {xAxisMin: _minX, xAxisMax: _maxX, yAxisMin: _minY, yAxisMax: _maxY};
            } else {
                extremes.xAxisMin = _minX < extremes.xAxisMin ? _minX : extremes.xAxisMin;
                extremes.xAxisMax = _maxX > extremes.xAxisMax ? _maxX : extremes.xAxisMax;
                extremes.yAxisMin = _minY < extremes.yAxisMin ? _minY : extremes.yAxisMin;
                extremes.yAxisMax = _maxY > extremes.yAxisMax ? _maxY : extremes.yAxisMax;
            }
        }
    });
    if (extremes) {
        let offsetArray;
        if (Number.isInteger(offset)) {
            offsetArray = [offset, offset, offset, offset];
        } else if (Array.isArray(offset) && offset.length === 4) {
            offsetArray = offset;
        }
        extremes.xAxisMin -= offsetArray[0];
        extremes.xAxisMax += offsetArray[1];
        extremes.yAxisMin -= offsetArray[2];
        extremes.yAxisMax += offsetArray[3];
    }
    return extremes;
}
