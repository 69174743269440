window.BootstrapAlert = function(type, message) {
    let getIconByType = {
        'primary': 'fa-info-circle',
        'info': 'fa-info-circle',
        'success': 'fa-check-circle',
        'warning': 'fa-exclamation-triangle',
        'danger': 'fa-exclamation-circle',
    };

    return `
        <div class="alert alert-${type} alert-outline-coloured alert-dismissible" role="alert">
            <button type="button" class="close" data-dismiss="alert">×</button>
            <div class="alert-icon d-flex align-items-center"><i class="fas fa-fw ${getIconByType[type]}"></i></div>
            <div class="alert-message">${message}</div>
        </div>
    `;
}
