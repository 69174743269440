$(function () {
    // Sidebar toggle behaviour
    $('.sidebar-toggle').on('click', function () {
        $('.sidebar')
            .toggleClass('toggled')
            // Triger resize after animation
            .one('transitionend', function () {
                setTimeout(function () {
                    window.dispatchEvent(new Event('resize'));
                }, 100);
            });
    });

    // $('.sidebar .active').parents('.collapse').addClass('show');
});
